
export const color1 = "#394c75"
export const color2 = "#0b17a5"
export const color3 = "#477589"
export const color4 = "#3d445f"
export const color5 = "#171317"

export const backgroundColor = color5
//export const backgroundColor = "#223";
export const color = "#fff";


