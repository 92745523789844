import React from 'react';

const BasicSilhouette = ({x= 0, y= 0, width= 217.01, height= 428.85, targetId= null}) => {
    return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.01 428.85" x={x} y={y} width={width} height={height}>
        <g id="target" data-target-id={targetId}>
            <path id="outline" style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                  d="M214.37,382.58h-9.32c1.14,7.38,2.22,13.52,3.32,19.66,1.61,9-2,16.79-8.15,22.41-5.48,5.05-13,5-20,1.35-.42-.22-.92-.3-.09,0-2.54,1.15-4,2.38-5.47,2.38q-66.39.17-132.79,0c-1.47,0-3-1.17-3.7-1.49-5.24.23-10,.6-14.82.61-5.71,0-8.57-3.83-11.32-8.34-5.12-8.43-4.23-17.08-2.15-26,.71-3,1.12-6.11,1.74-9.56H3c-.55-4.59-1.18-8.81-1.54-13.05C-.37,349.28.22,328,1,306.74c1.15-31.44,1.88-62.91,2-94.37,0-16.43-2-32.86-2.44-49.3a85.79,85.79,0,0,1,2.14-21.19,24.46,24.46,0,0,1,16-18.28q25.46-9.39,50.9-18.83a63.94,63.94,0,0,0,7.71-3.43c4-2.11,5.63-5.32,6-10,1-12-4.47-22.68-5.58-34.16S75.6,34.07,75.37,22.51C75.18,13.45,80.55,7.08,88.73,3.9c12.46-4.85,25.3-4.72,38-.49C137.2,6.93,141.75,15.09,142,25.61c.43,17-3.28,33.53-7,50.05-1.43,6.34-1.25,13.12-1.08,19.68.05,1.79,2.41,4,4.24,5.16a53.11,53.11,0,0,0,9.82,4.71c16.17,6,32.33,12.09,48.59,17.86,11.33,4,16.7,12.3,18.47,23.71,2.57,16.61.25,33.11-.6,49.67-.68,13.29-.68,26.63-.38,39.94.76,33.45,2.26,66.89,2.66,100.34C216.93,352.11,215.24,367.51,214.37,382.58Z"/>
            <path id="_6-ring" data-name="6-ring" style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                  d="M187.72,180.33V284.84c0,41.83-35.37,75.74-79,75.74s-79-33.91-79-75.74V180.33c0-41.84,35.37-75.75,79-75.75S187.72,138.49,187.72,180.33Z"/>
            <path id="_7-ring" data-name="7-ring" style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                  d="M168.72,194.71v76.7c0,31.78-26.86,57.53-60,57.53s-60-25.75-60-57.53v-76.7c0-31.78,26.86-57.53,60-57.53S168.72,162.93,168.72,194.71Z"/>
            <path id="_8-ring" data-name="8-ring" style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                  d="M148.22,207.17V259c0,21.18-17.91,38.35-40,38.35s-40-17.17-40-38.35V207.17c0-21.18,17.91-38.35,40-38.35S148.22,186,148.22,207.17Z"/>
            <path id="_9-ring" data-name="9-ring" style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                  d="M128.72,218.68v28.76c0,.32,0,.65,0,1-.52,10.15-9.26,18.22-20,18.22s-19.45-8.07-20-18.22c0-.31,0-.64,0-1V218.68c0-10.6,9-19.18,20-19.18S128.72,208.08,128.72,218.68Z"/>
            <path id="_10-ring" data-name="10-ring" style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                  d="M119.72,224.91v15.34a8.57,8.57,0,0,1-.11,1.44,11.06,11.06,0,0,1-21.78,0,8.57,8.57,0,0,1-.11-1.44V224.91c0-.16,0-.32,0-.48a11,11,0,0,1,22,0C119.72,224.59,119.72,224.75,119.72,224.91Z"/>
            <g id="X">
                <path
                    d="M111.33,235.53h-.94l-1.5-2.19-1.55,2.19h-.89l2-2.8-2-2.79h1l1.5,2.17,1.53-2.17h.89l-2,2.76Z"/>
            </g>
            <g id="_9" data-name="9">
                <path
                    d="M110.32,258a2.28,2.28,0,0,1-1.54.57,2.23,2.23,0,0,1-1.61-.61,2.09,2.09,0,0,1-.64-1.59,2.58,2.58,0,0,1,.62-1.75,2,2,0,0,1,1.58-.71,2.09,2.09,0,0,1,1.86,1.08,5.22,5.22,0,0,1,.66,2.76,5.07,5.07,0,0,1-.78,2.85,2.4,2.4,0,0,1-2.09,1.17,4,4,0,0,1-1.53-.26l.14-.78a4,4,0,0,0,1.37.27,1.61,1.61,0,0,0,1.42-.86A4,4,0,0,0,110.32,258Zm0-.76a3.67,3.67,0,0,0-.42-1.79,1.23,1.23,0,0,0-1.08-.77,1.17,1.17,0,0,0-1,.45,1.82,1.82,0,0,0-.37,1.17,1.61,1.61,0,0,0,.37,1.08,1.24,1.24,0,0,0,1,.42,2.22,2.22,0,0,0,1.43-.5Z"/>
            </g>
            <g id="_8" data-name="8">
                <path
                    d="M109.67,280.79a2.35,2.35,0,0,1,1.2.77,2,2,0,0,1,.42,1.27,2.09,2.09,0,0,1-.68,1.65,2.73,2.73,0,0,1-3.44,0,2.1,2.1,0,0,1-.68-1.64,1.94,1.94,0,0,1,.44-1.27,2.43,2.43,0,0,1,1.23-.78,1.85,1.85,0,0,1-1-.62,1.58,1.58,0,0,1-.38-1,1.87,1.87,0,0,1,.58-1.32,2,2,0,0,1,1.53-.59,2.13,2.13,0,0,1,1.56.59,1.78,1.78,0,0,1,.61,1.32,1.59,1.59,0,0,1-.38,1A1.92,1.92,0,0,1,109.67,280.79Zm-.79.39c-1,.29-1.47.82-1.47,1.62a1.41,1.41,0,0,0,.45,1.1,1.47,1.47,0,0,0,2.52-1.1,1.67,1.67,0,0,0-.29-1A2.1,2.1,0,0,0,108.88,281.18Zm0-.75.26-.07a1.34,1.34,0,0,0,.4-.16,1.13,1.13,0,0,0,.3-.24,1.2,1.2,0,0,0,.23-.34,1.18,1.18,0,0,0,.08-.43,1.11,1.11,0,0,0-.37-.86,1.28,1.28,0,0,0-.9-.34,1.18,1.18,0,0,0-.87.35,1.1,1.1,0,0,0-.37.85,1.21,1.21,0,0,0,.13.55,1.23,1.23,0,0,0,.33.38,1.59,1.59,0,0,0,.45.23A1.74,1.74,0,0,0,108.88,280.43Z"/>
            </g>
            <g id="_7" data-name="7">
                <path d="M111.14,308.92l-3,6.95h-.91l3-6.81h-3.55v-.77h4.5Z"/>
            </g>
            <g id="_6" data-name="6">
                <path
                    d="M107.47,345.63a2.39,2.39,0,0,1,1.54-.57,2.22,2.22,0,0,1,1.6.61,2.09,2.09,0,0,1,.64,1.59,2.72,2.72,0,0,1-.57,1.77,1.87,1.87,0,0,1-1.52.7,2.18,2.18,0,0,1-1.92-1.12,5,5,0,0,1-.71-2.73,5.25,5.25,0,0,1,.74-2.87,2.28,2.28,0,0,1,2-1.15,4,4,0,0,1,1.53.26l-.14.78a4,4,0,0,0-1.36-.27,1.52,1.52,0,0,0-1.37.84A4.32,4.32,0,0,0,107.47,345.63Zm0,.76a3.44,3.44,0,0,0,.46,1.77,1.32,1.32,0,0,0,1.15.79,1,1,0,0,0,.9-.43,2,2,0,0,0,.32-1.19,1.65,1.65,0,0,0-.35-1.1,1.25,1.25,0,0,0-1-.4A2.31,2.31,0,0,0,107.49,346.39Z"/>
            </g>
            </g>
    </svg>
    )
}

export default BasicSilhouette