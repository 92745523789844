import React from 'react';

const USPSA = ({x=0, y=0, width=237, height=377.82, targetId = null}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 237 377.82" x={x} y={y} width={width} height={height}>
            <g id="USPSA" data-target-id={targetId}>
                <path id="D-target" data-name="D-target" style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                      d="M40,377.57.25,300.17,2.4,115.27l-.34-6.1,30.1-30.1,5.72-5.72H197l7.86,6.25,30.1,30.1-.34,6.11,2.15,184.9-2.45,4.78-36.25,72.08H40Z"/>
                <path id="C-target" data-name="C-target"  style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                      d="M68.74,300.71,43.79,242.87l1.35-138.19-.22-4.56L63.8,77.62l3.59-4.27h99.8L172.12,78,191,100.52l-.21,4.56,1.35,138.19-1.54,3.57-22.74,53.87H68.74Z"/>
                <path id="A-body-target" data-name="A-body-target"  style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                      d="M148.75,246.43H89.46a6.37,6.37,0,0,1-6.37-6.37V116.27a6.37,6.37,0,0,1,6.37-6.37h59.29a6.37,6.37,0,0,1,6.37,6.37V240.06A6.38,6.38,0,0,1,148.75,246.43Z"/>
                <path id="B-target" data-name="B-target"  style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}} d="M160.43,73.35H75.5V.25h84.93Z"/>
                <path id="A-head-target" data-name="A-head-target"  style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                      d="M138.52,43.25H99.68a6.38,6.38,0,0,1-6.38-6.38V22.76a6.38,6.38,0,0,1,6.38-6.38h38.84a6.37,6.37,0,0,1,6.38,6.38V36.87A6.38,6.38,0,0,1,138.52,43.25Z"/>
                <g id="A">
                    <path style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                          d="M117.34,207.53l-.9,2.73h-1.16l3-8.69h1.35l3,8.69h-1.2l-.93-2.73Zm2.85-.88-.85-2.5c-.2-.57-.32-1.08-.45-1.59h0c-.13.52-.27,1.05-.44,1.58l-.85,2.51Z"/>
                </g>
                <g id="C">
                    <path style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                          d="M68.74,210.19a5.67,5.67,0,0,1-2.29.41,4.07,4.07,0,0,1-4.3-4.4,4.33,4.33,0,0,1,4.54-4.56,4.5,4.5,0,0,1,2.06.39l-.27.91a4,4,0,0,0-1.75-.36,3.23,3.23,0,0,0-3.39,3.58,3.16,3.16,0,0,0,3.34,3.5,4.48,4.48,0,0,0,1.83-.36Z"/>
                </g>
                <g id="C-2" data-name="C">
                    <path style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                          d="M178.37,210a5.44,5.44,0,0,1-2.29.42,4.07,4.07,0,0,1-4.3-4.4,4.33,4.33,0,0,1,4.54-4.56,4.79,4.79,0,0,1,2.07.38l-.27.92a4.09,4.09,0,0,0-1.76-.36A3.23,3.23,0,0,0,173,206a3.16,3.16,0,0,0,3.34,3.5,4.47,4.47,0,0,0,1.83-.37Z"/>
                </g>
                <g id="D">
                    <path style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                          d="M211.3,201.68a16.39,16.39,0,0,1,2.38-.18,5,5,0,0,1,3.52,1.08,4,4,0,0,1,1.23,3.12,4.61,4.61,0,0,1-1.25,3.38,5.4,5.4,0,0,1-3.85,1.25,19.06,19.06,0,0,1-2-.1Zm1.12,7.69a7.24,7.24,0,0,0,1.13.06,3.34,3.34,0,0,0,3.7-3.69c0-2.05-1.15-3.35-3.52-3.35a5.86,5.86,0,0,0-1.31.12Z"/>
                </g>
                <g id="D-2" data-name="D">
                    <path style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                          d="M21.62,201.88A16.54,16.54,0,0,1,24,201.7a5,5,0,0,1,3.52,1.09,4,4,0,0,1,1.22,3.12,4.6,4.6,0,0,1-1.25,3.37,5.31,5.31,0,0,1-3.84,1.26,16.81,16.81,0,0,1-2-.11Zm1.12,7.69a7.45,7.45,0,0,0,1.14.06,3.34,3.34,0,0,0,3.7-3.68c0-2.06-1.15-3.36-3.52-3.36a6,6,0,0,0-1.32.12Z"/>
                </g>
                <g id="A-2" data-name="A">
                    <path style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                          d="M117.34,30.94l-.9,2.73h-1.16l3-8.69h1.35l3,8.69h-1.2l-.93-2.73Zm2.85-.88-.85-2.5c-.2-.57-.32-1.08-.45-1.58h0c-.13.51-.27,1-.44,1.57l-.85,2.51Z"/>
                </g>
                <g id="B">
                    <path style={{fill:"#fff", stroke: "#000", strokeMiterlimit: 10, strokeWidth: "0.5px"}}
                          d="M116.36,52.92a9.84,9.84,0,0,1,1.91-.17,3.33,3.33,0,0,1,2.22.59,1.68,1.68,0,0,1,.67,1.42,1.93,1.93,0,0,1-1.37,1.78v0a2.09,2.09,0,0,1,1.67,2,2.21,2.21,0,0,1-.68,1.62A4.07,4.07,0,0,1,118,61a12.11,12.11,0,0,1-1.62-.09Zm1,3.31h.94c1.11,0,1.75-.58,1.75-1.36,0-.95-.71-1.32-1.77-1.32a4.9,4.9,0,0,0-.92.07Zm0,3.87a5,5,0,0,0,.87,0c1.08,0,2.08-.4,2.08-1.57s-1-1.56-2.09-1.56h-.86Z"/>
                </g>
            </g>
        </svg>
    )
}

export default USPSA